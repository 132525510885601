<template>
  <v-container fluid>
    <v-row class="justify-center">
      <h1>{{ language == "en" ? "送受信メール管理" : "送受信メール管理" }}</h1>
      <!-- <v-spacer /> -->

      <!-- <v-card>         :style="!isAd min ? 'display:none;' : ''"
</v-card> -->
      <!-- <v-checkbox
        v-if="isAdmin"
        @change="DisplayAllMessage()"
        v-model="allMessage"
        class="d-flex flex-row-reverse ml-12"
        label="
      All Message
      "
      ></v-checkbox> -->
      <v-col cols="12" class="pa-3 justify-center">
        <!-- <v-row class="justify-center">
          <v-col cols="1"
            ><h2 style="white-space: nowrap !important">Finished :</h2>
          </v-col>
          <v-col cols="1">
            <div
              style="
                width: 100px;
                height: 25px;
                background-color: #88f28d;
                border: 2px solid #000;
              "
            ></div
          ></v-col>
          <v-col cols="1"
            ><h2 style="white-space: nowrap !important">Overdue :</h2>
          </v-col>
          <v-col cols="1">
            <div
              style="
                width: 100px;
                height: 25px;
                background-color: #ff5347;
                border: 2px solid #000;
              "
            ></div
          ></v-col>
          <v-col cols="1"
            ><h3 style="white-space: nowrap !important">No Reply Yet :</h3>
          </v-col>
          <v-col cols="1">
            <div
              style="
                width: 100px;
                height: 25px;
                background-color: #fff;
                border: 2px solid #000;
              "
            ></div
          ></v-col>
        </v-row> -->
        <v-row>
          <v-col cols="3">
            <v-text-field
              label="Search"
              outlined
              dense
              v-model="search"
              hide-details
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              multiple
              label="Filter"
              outlined
              dense
              :items="filterStatusArr"
              v-model="filterStatus"
              hide-details
              prepend-inner-icon="mdi-filter"
              @change="FilterFunction(filterStatus)"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+ {{ filterStatus.length - 1 }} others)
                </span>
              </template>
              <template v-slot:prepend-item>
                <v-list-item ripple @mousedown.prevent @click="toggleFilter">
                  <v-list-item-action>
                    <!-- Uncomment the icon rendering -->
                    <v-icon :color="likesAllFruitFilter ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Select All </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>

          <!-- <v-col cols="2">
            <v-select
              label="Select Column"
              outlined
              dense
              :items="headerselector"
              v-model="headerSelected"
              hide-details
              multiple
              prepend-inner-icon="mdi-table-column-width"
              @change="changeHeader(headerSelected)"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+ {{ headerSelected.length - 1 }} others)
                </span>
              </template>
              <template v-slot:prepend-item>
                <v-list-item ripple @mousedown.prevent @click="toggle">
                  <v-list-item-action>
                    <v-icon :color="headerselector.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Select All </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col> -->
          <v-col cols="2" class="pr-0">
            <v-text-field
              v-model="FromDate"
              label="FromDate"
              type="date"
              outlined
              dense
              hide-details
              :max="calculateMaxDate()"
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="pl-1 pr-0">
            <v-text-field
              v-model="ToDate"
              label="ToDate"
              type="date"
              outlined
              dense
              hide-details
              :max="calculateMaxDate()"
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="pl-1 pt-3">
            <v-btn @click="getBetweenDate()" color="error">Find</v-btn>
          </v-col>
        </v-row>
        <div v-if="!loading">
          <v-simple-table
            class="table"
            hide-default-header
            :items="getSearch"
            centered
            fixed
            height="600"
          >
            <thead>
              <tr>
                <th
                  style="width 100px;"
                  v-for="h in selectedColumns"
                  :key="h.comment"
                  :class="h.class"
                >
                  {{ language == "en" ? h.en : h.ja }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getSearch" :key="item.id" :class="getRowStyle(item)">
                <td style="width: 30px" v-for="col in selectedColumns" :key="col.comment">
                  <!-- <template v-if="renderColumnValue(item, col.en) === 'attachmentIcon'">
                    <v-btn
                      x-small
                      outlined
                      tag="span"
                      :to="{
                        name: 'receivedReply',
                        params: { id: item.ConfirmationID },
                      }"
                    >
                      OPEN
                    </v-btn>
                  </template> -->
                  <template v-if="renderColumnValue(item, col.en) === 'toFinish'">
                    <v-btn
                      v-if="item.Status == 'Finished' && item.ReplyDate != 'Invalid date'"
                      @click="updateInquiry(item)"
                      color="success"
                      small
                      readonly
                    >
                      <v-icon>mdi-flag</v-icon>
                    </v-btn>

                    <v-btn
                      v-else-if="
                        item.Status == 'Finished' && item.ReplyDate == 'Invalid date'
                      "
                      @click="updateInquiry(item)"
                      color="success"
                      small
                      ><v-icon small>mdi-flag</v-icon>
                    </v-btn>
                    <v-btn
                      @click="finishedInquiry(item)"
                      v-else-if="
                        item.Status === 'Not yet reply' &&
                        item.ReplyDate === 'Invalid date'
                      "
                      color="primary"
                      small
                    >
                      <v-icon small>mdi-send</v-icon>
                    </v-btn>
                    <v-btn @click="finishedInquiry(item)" v-else color="primary" small>
                      On going Conversation<v-icon small>mdi-send</v-icon>
                    </v-btn>
                  </template>

                  <template
                    v-else-if="renderColumnValue(item, col.en) === 'coversationIcon'"
                  >
                    <v-btn @click="OpenThread(item.ConfirmationID)" small outlined>
                      OPEN <v-icon small>mdi-message-outline</v-icon>
                    </v-btn>
                    <!-- <v-icon @click="OpenThread(item.ConfirmationID)"
                      >mdi-message-outline</v-icon
                    > -->
                  </template>
                  <template v-else>
                    {{ renderColumnValue(item, col.en) }}
                  </template>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div v-else>
          <v-overlay style="text-align: center" v-model="loading">
            <h1>Getting data please wait...</h1>
            <br />
            <br />

            <v-progress-circular width="5" size="90" color="white" indeterminate>
              <h1 style="text-shadow: 0 0 10px black; text-align: center"></h1
            ></v-progress-circular>
          </v-overlay>
          <!-- <h1 style="text-align:center">Please Wait...</h1>
          <h1 style="text-align:center">It takes more Time</h1> -->
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <v-pagination
          v-model="currentPage"
          :length="totalPages"
          :total-visible="5"
        ></v-pagination>
      </v-col>
    </v-row>
    <ReplyThread ref="replyThread" />
  </v-container>
</template>

<script>
import Swal from "sweetalert2";

import axios from "axios";
const baseUrl = keys.auth.BaseUrl;
const apikey = keys.auth.apiKey;
import keys from "../../functions/alert";
import moment from "moment";
import store from "@/store";
// import AWSAttachment from "../../functions/Attachment";
import InquiryStatusTable from "../../functions/httpRequest/ExternalConnection/InquiryStatusTable";

import ReplyThread from "../../../src/components/MastersComponent/InquiryStatus/ReplyTread.vue";
export default {
  data() {
    return {
      isAdmin: store.state.userinfo.isAdmin,
      allMessage: false,
      itemsPerPage: 20,
      totalPages: 0,
      currentPage: 1,
      filterStatus: ["No Reply Yet"],
      filterStatusArr: [
        "No Reply Yet",
        "No Due Date",
        "No Reply Date",
        "All Correction",
        "All Inquiry",
        "On going Conversation",
        "Finished",
        "Finished without reply date",
      ],
      interval: {},
      FromDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
      ToDate: moment().format("YYYY-MM-DD"),
      // 2023-08-31,

      loading: false,
      Finished: true,
      NotFinished: false,
      headers: [
        {
          en: "Status",
          ja: "ステータス",
          sortable: false,
          value: "FinishedDate",
          class: "my-header-column1",
        },
        {
          en: "Sent Date",
          ja: "送信時間",
          sortable: false,
          value: "DateCreated",
          class: "my-header-column234",
        },
        {
          en: "Due Date",
          ja: "返信期限日",
          sortable: false,
          value: "DueDate",
          class: "my-header-column234",
        },
        {
          en: "Reply Date",
          ja: "[返信時間]",
          sortable: false,
          value: "ReplyDate",
          class: "my-header-column234",
        },
        {
          en: "Customer Code",
          ja: "物件コード",
          sortable: false,
          value: "CustomerCode",
          class: "my-header-column56789",
        },
        {
          en: "Confirmation Category",
          ja: "問い合わせカテゴリー",
          sortable: false,
          value: "",
          class: "my-header-column56789",
        },
        {
          en: "Open Mail Box",
          ja: "メール確認",
          sortable: false,
          value: "MessageContent",
          class: "my-header-column56789",
        },
        // {
        //   en: "Attched File",
        //   ja: "添付ファイル",
        //   sortable: false,
        //   value: "Attachment",
        //   class: "my-header-column56789",
        // },
        // {
        //   en: "Open Mail Box",
        //   ja: "メール確認",
        //   sortable: false,
        //   value: "SeenBy",
        //   class: "my-header-column56789",
        // },
        {
          en: "Sender Name",
          ja: "送信者",
          sortable: false,
          value: "SenderName",
          class: "my-header-columnlast4",
        },
        // {
        //   en: "Sender Email Account",
        //   ja: "送信者アドレス",
        //   sortable: false,
        //   value: "SenderEmail",
        //   class: "my-header-columnlast4",
        // },
        {
          en: "Receiver Name",
          ja: "受信者",
          sortable: false,
          value: "",
          class: "my-header-columnlast4",
        },
        // {
        //   en: "Receiver Email Account",
        //   ja: "受信者アドレス",
        //   sortable: false,
        //   value: "",
        //   class: "my-header-columnlast4",
        // },
      ],
      headerselector: [
        "Status",
        "Sent Date",
        "Due Date",
        "Reply Date",
        "Customer Code",
        "Confirmation Category",
        // "Confirmation Message",
        "Open Mail Box",
        "Sender Name",
        "Sender Email Account",
        "Receiver Name",
        "Receiver Email Account",
      ],
      temporaryFilterRecord: [],
      inquiryStatusRecord: [],
      search: "",
      startProgress: "",
      EndProgress: "",
      selectedColumns: [],
      headerSelected: [],
    }; //end return
  }, //end data
  watch: {
    currentPage() {
      this.loading = true;
      this.InquiryCount();
    },
  },
  components: {
    ReplyThread: ReplyThread,
  },
  methods: {
    DisplayAllMessage() {
      console.log(this.allMessage);
      this.loading = true;
      this.InquiryCount();
    },
    updateInquiry(obj) {
      Swal.fire({
        title: "Update Inquiry?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log(this.Reply);

          const updateStatus = {
            ConfirmationID: obj.ConfirmationID,
            Status: "Not yet reply",
            FollowupStatus: null,
          };
          InquiryStatusTable.UpdateFinish(updateStatus);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "This Inquiry has been updated.",
            showConfirmButton: false,
            timer: 1500,
          });
          let index = this.inquiryStatusRecord.indexOf(obj);
          if (index !== -1) {
            this.inquiryStatusRecord[index].Status = "Not yet reply";
          } else {
            console.log("Element not found in the array");
          }
        }
      });
    },
    finishedInquiry(obj) {
      Swal.fire({
        title: "Finish Inquiry?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log(this.Reply);

          const updateStatus = {
            ConfirmationID: obj.ConfirmationID,
            Status: "Finished",
            FollowupStatus: 4,
          };
          InquiryStatusTable.UpdateFinish(updateStatus);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "This Inquiry has been finished.",
            showConfirmButton: false,
            timer: 1500,
          });
          let index = this.inquiryStatusRecord.indexOf(obj);
          if (index !== -1) {
            this.inquiryStatusRecord[index].Status = "Finished";
          } else {
            console.log("Element not found in the array");
          }
        }
      });
    },
    getRowStyle(item) {
      if (item.Status != "Finished" && item.DueDate < this.dateNow) {
        return "dueColor";
      } else if (item.Status == "Finished") {
        return "finishColor";
      } else {
        return "notDueColor";
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.headerSelected = [];
          this.selectedColumns = [];
        } else {
          this.selectedColumns = this.headers.filter((header) =>
            this.headerselector.includes(header.en)
          );
          this.headerSelected = this.headerselector.slice();
        }
      });
    },
    toggleFilter() {
      this.$nextTick(() => {
        if (this.likesAllFruitFilter) {
          this.filterStatus = [];
        } else {
          this.filterStatus = this.filterStatusArr.slice();
        }
        this.FilterFunction(this.filterStatus);
      });
    },
    changeHeader(selectedColumns) {
      // this.selectedColumns = selectedColumns;
      this.selectedColumns = this.headers.filter((header) =>
        selectedColumns.includes(header.en)
      );
    },

    renderColumnValue(item, column) {
      switch (column) {
        case "Status":
          return "toFinish";
        case "Sent Date":
          return this.changeDateFormat(item.ReceivedDate, item.ReceivedTime);
        case "Due Date":
          return item.DueDate == "Invalid date"
            ? "No Due Date"
            : this.changeDateFormat(item.DueDate, item.ReceivedTime);
        case "Reply Date":
          return item.ReplyDate == "Invalid date"
            ? "No Reply Date"
            : this.changeDateFormat(item.ReplyDate, item.ReplyTime);
        case "Customer Code":
          return item.CustomerCode;
        case "Confirmation Category":
          return item.Type == "0" ? "Correction" : "Inquiry";
        case "Open Mail Box":
          return "coversationIcon";
        // case "Open Mail Box":
        //   return "attachmentIcon";
        case "Sender Name":
          return item.SenderName;
        case "Sender Email Account":
          return item.SenderEmail;
        case "Receiver Name":
          return item.To;
        case "Receiver Email Account":
          return item.ToEmail;
        // Add cases for other columns...
        default:
          return ""; // Set default value for unhandled columns
      }
    },
    percentage(startProgress, EndProgress) {
      let percentage = (startProgress / EndProgress) * 100;
      return percentage.toFixed();
    },
    FilterFunction(val) {
      // console.log(this.filterStatus, "603");
      let all = this.temporaryFilterRecord;

      this.inquiryStatusRecord = all.filter((rec) => {
        return (
          val.includes("All") ||
          (val.includes("No Reply Yet") &&
            rec.Status === "Not yet reply" &&
            rec.ReplyDate == "Invalid date") ||
          (val.includes("On going Conversation") &&
            rec.Status === "Not yet reply" &&
            rec.ReplyDate != "Invalid date") ||
          (val.includes("Finished without reply date") &&
            rec.Status == "Finished" &&
            rec.ReplyDate == "Invalid date") ||
          (val.includes("Finished") &&
            rec.Status == "Finished" &&
            rec.ReplyDate != "Invalid date")
        );
      });
    },
    calculateMaxDate() {
      // Calculate the max date dynamically, for example, 0 days from today
      const maxDate = moment().add(0, "days").format("YYYY-MM-DD");
      return maxDate;
    },
    OpenThread(id) {
      this.$refs.replyThread.OpenDialog(id);
    },
    changeDateFormat(val, time) {
      let fulltime = val + " " + time;
      return moment(fulltime).format("YYYY/MM/DD hh:mm:ss");
    },
    // openAttachment(item) {
    //   var ResponseContentTypes = "";
    //   var Path = item.Path;
    //   var Attachment = item.Attachment;
    //   var contentType = Attachment.substr(Attachment.lastIndexOf(".") + 1);

    //   if (contentType === "pdf") {
    //     ResponseContentTypes = "application/" + contentType;
    //   } else if (
    //     contentType === "jpg" ||
    //     contentType === "jpeg" ||
    //     contentType === "png"
    //   ) {
    //     ResponseContentTypes = "image/" + contentType;
    //   } else {
    //     console.log("Other format Attachment");
    //   }
    //   AWSAttachment.openFileParams(Path, Attachment, ResponseContentTypes);
    // },
    async InquiryCount() {
      this.inquiryStatusRecord = [];
      try {
        const { data, status } = await axios.get(
          `${baseUrl}/getInquiryStatusCount/${this.FromDate}/${this.ToDate}/${store.state.userinfo.Email}/${this.allMessage}`,
          apikey
        );
        if (status == 200) {
          let countData = data[0].InquiryCount;
          // let MathCount = Math.floor(countData / 5000);
          // this.EndProgress = MathCount;

          await this.fetchProducts(countData);

          this.FilterFunction(this.filterStatus);
        } else {
          console.log("Server returned a non-200 status code:", status);
          // this.loading = true;
        }
        // console.log(data, "data");
        // console.log(status, "status");
      } catch (error) {
        // console.error("An error occurred while making the request:", error);
        this.startProgress = error;
        console.log(error);
        // location.reload();
        // Handle the error appropriately, e.g., show an error message to the user.
      }
    },
    getBetweenDate() {
      this.inquiryStatusRecord = [];
      this.InquiryCount();
    },
    fetchProducts(totalItemsResult) {
      this.loading = true;

      console.log(totalItemsResult);
      axios
        .get(
          `${baseUrl}/getInquiryStatusMailbox/${totalItemsResult}/${this.FromDate}/${this.ToDate}/${store.state.userinfo.Email}/${this.allMessage}/${this.filterStatus}`,
          {
            headers: { "x-api-key":"ZUe7gbLExb2OyqUHGdwXT9r8whee39ivabgYIXdJ"},
            params: {
              page: this.currentPage,
              page_size: this.itemsPerPage,
            },
          }
        )
        .then((res) => {
          const data = res.data;
          let filterInquiry = data.products.map((rec) => ({
            ...rec,
            DueDate: moment(rec.DueDate).format("YYYY-MM-DD"),
            ReceivedDate: moment(rec.ReceivedDate).format("YYYY-MM-DD"),
            ReplyDate: moment(rec.ReplyDate).format("YYYY-MM-DD"),
          }));

          // Assign the filtered data directly to inquiryStatusRecord
          this.inquiryStatusRecord = filterInquiry;

          // Log the total items
          console.log(data.totalItem, "709");

          // Assign the filtered data directly to temporaryFilterRecord without concatenation
          this.temporaryFilterRecord = filterInquiry;

          // Calculate the total number of pages
          this.totalPages = Math.ceil(data.totalItem / this.itemsPerPage);
          this.loading = false;

          // Set loading to false
        })
        .catch((error) => {
          // Handle Axios request errors here
          console.log(error);
        });
    },
  }, // end method
  beforeDestroy() {
    clearInterval(this.interval);
  }, //end beforeDestroy
  computed: {
    numPages() {
      return Math.ceil(this.inquiryStatusRecord.length / this.itemsPerPage);
    },
    likesAllFruit() {
      return this.headerSelected.length === this.headerselector.length;
    },
    likesSomeFruit() {
      return this.headerSelected.length > 0 && !this.likesAllFruit;
    },
    likesAllFruitFilter() {
      return this.filterStatus.length === this.filterStatusArr.length;
    },
    likesSomeFruitFilter() {
      return this.filterStatus.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllFruitFilter) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    language() {
      return this.$i18n.locale;
    },
    dateNow() {
      return moment().format("YYYY-MM-DD");
    },
    FilteredRecord() {
      let record = this.inquiryStatusRecord;
      // console.log(record);
      return record;
    },

    getCurrentPageItems() {
      const startIdx = (this.page - 1) * this.itemsPerPage;
      const endIdx = startIdx + this.itemsPerPage;
      return this.FilteredRecord.slice(startIdx, endIdx);
    },
    getSearch() {
      return this.inquiryStatusRecord.filter((rec) => {
        return JSON.stringify(Object.values(rec))
          .toUpperCase()
          .includes(this.search.toUpperCase());
      });
    },
  }, //end computed
  // beforeCreate() {
  //   this.isAdmin = store.state.userinfo.isAdmin;
  //   // console.log(this.isAdmin);
  // },
  created() {
    this.headerSelected = [];
    this.filterStatus; // Ensure headerSelected is empty when the component is created
    this.selectedColumns = this.headers.filter((header) =>
      this.headerselector.includes(header.en)
    );
    this.language;
    this.loading = true;
    this.FilterFunction(this.filterStatus);
  }, //end created
  mounted() {
    // this.renderColumnValue();
    this.InquiryCount();
    // this.fetchProducts();

    // this.interval = setInterval(() => {
    //   if (this.value === 100) {
    //     return (this.value = 0);
    //   }
    //   this.value += 10;
    // }, 1000);
  }, //end mounted
}; //end of export default
</script>

<style scoped>
.finishColor {
  background-color: #88f28d !important;
  border: 0.1px solid black !important;
  text-align: center !important;
  padding: 5px !important;
}
.dueColor {
  background-color: #ff5347 !important;
  border: 0.1px solid black !important;
  text-align: center !important;
  padding: 5px !important;
}
.my-header-column1 {
  width: 5%;
  white-space: pre-wrap;
  background-color: #00b0f0;
  border: 1px solid black;
  padding: 8px !important;
  text-align: center !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1;
}
.my-header-column234 {
  width: 5%;
  white-space: pre-wrap;
  background-color: #ffc000;
  border: 1px solid black;
  padding: 8px !important;
  text-align: center !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1;
}
.my-header-column56789 {
  width: 5%;
  white-space: pre-wrap;
  background-color: #fff200;
  padding: 8px !important;
  border: 1px solid black;
  text-align: center !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1;
}
.my-header-columnlast4 {
  width: 5%;
  white-space: pre-wrap;
  background-color: #e2f0d9;
  border: 1px solid black;
  padding: 8px !important;
  text-align: center !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1;
}
tr {
  border: 0.1px solid black !important;
  text-align: center !important;
  white-space: nowrap !important;
}
.scrollable-table-container {
  max-height: 600px; /* Adjust the height as needed */
  overflow-y: auto;
  overflow-x: auto; /* Enable vertical scrollbar if needed */
}
.notDueColor {
  /* font-size: 9px !important; */
  background-color: #fff;
  border: 0.1px solid black !important;
  text-align: center !important;
  padding: 5px !important;

  /* z-index: 1 !important; */
}
table {
  width: 100%;
  table-layout: fixed; /* Ensures that the table's width is fixed */
  border-collapse: collapse; /* Ensures that borders are collapsed */
}

th,
td {
  width: 11.11%; /* Adjust the percentage as needed to ensure all columns are the same width */
  text-align: center; /* Center text within cells */
  vertical-align: middle; /* Vertically center the text within cells */
  padding: 8px; /* Optional: Add some padding for better readability */
  border: 1px solid black; /* Optional: Add borders for better visibility */
  word-wrap: break-word; /* Ensures that long text will wrap to the next line */
  white-space: normal; /* Ensures that text will wrap to the next line */
}
.Loader {
  margin-left: 45%;
  margin-top: 15%;
}
</style>
